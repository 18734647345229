table {
  margin: 40px auto;
  border-collapse: collapse;
  overflow-wrap: anywhere;
}

th {
  font-weight: var(--semi-bold);
}

th,
td {
  padding: 10px 15px;
  border: solid 1px var(--light-gray);
}
